<script>
import { useToast } from "vue-toastification";
import { Modal } from "usemodal-vue3";
import { HalfCircleSpinner } from "epic-spinners";
import { useOrganizationsAuthStore } from "~/stores/organizationsAuth.js";
import "~/assets/css/styles.css";
export default {
	data: () => ({
		menuCollapsed: false,
		choice: 0,
		feedback: null,
		feedback_loading: false,
		related: [],
		current: {},
		manager: ref({ email: "", password: "" }),
		loading: false,
	}),
	setup() {
		const toast = useToast();
		const authStore = useOrganizationsAuthStore();
		const config = useRuntimeConfig();
		return {
			toast,
			authStore,
			config,
			aiToolModalOpened: ref(false),
			qrCodeToolModalOpened: ref(false),
			feedbackModalOpened: ref(false),
			switchAccountModalOpened: ref(false),
			connectAccountModalOpened: ref(false),
			disconnectAccountModalOpened: ref(false),
		};
	},
	created() {
		this.setCurrent();
	},
	computed: {
		managerInitials() {
			const nameParts = this.authStore.manager.split(" ");
			const initials = nameParts.map((part) => part.charAt(0).toUpperCase());
			return initials.join("");
		},
		currentRouteName() {
			return this.$route.name;
		},
		pages_selected() {
			return this.$route.name.startsWith("organizations-pages") || ["organizations-subcategories", "organizations-teams"].includes(this.$route.name);
		},
		data_selected() {
			return [
				"organizations-donors",
				"organizations-donations",
				"organizations-subscriptions",
				"organizations-promises"
			].includes(this.$route.name);
		},
		categories_selected() {
			return [
				"organizations-pages-categories",
				"organizations-subcategories"
			].includes(this.$route.name);
		},
		teams_selected() {
			return [
				"organizations-pages-campaigns",
				"organizations-teams"
			].includes(this.$route.name);
		},
		donors_selected() {
			return this.$route.name.startsWith("organizations-donors");
		},
		donations_selected() {
			return this.$route.name.startsWith("organizations-donations");
		},
		transactions_selected() {
			return [
				"organizations-donations-disputes",
				"organizations-donations-refunds"
			].includes(this.$route.name);
		},
		subscriptions_selected() {
			return this.$route.name.startsWith("organizations-subscriptions");
		},
		billing_selected() {
			return this.$route.name.startsWith("organizations-billing");
		},
	},
	methods: {
		setCurrent() {
			this.related = useOrganizationsAuthStore().getRelated();
			this.current = {
				name: useOrganizationsAuthStore().slug,
				slug: useOrganizationsAuthStore().slug,
			};
		},
		collapse() {
			this.menuCollapsed = !this.menuCollapsed;
		},
		sendFeedback() {
			this.feedback_loading = true;
			this.loading = true;
			organizationsFetch("/feedback", {
				method: "POST",
				body: {
					level: this.choice,
					comment: this.feedback,
				},
			})
				.then((response) => {
					this.feedback_loading = false;
					this.feedbackModalOpened = false;
					this.choice = false;
					this.feedback = null;
					this.toast.success(response.message);
				})
				.catch((error) => {
					this.feedback_loading = false;
					this.toast.error(error, {
						timeout: 20000,
					});
				});
		},
		switchAccount(account) {
			if (account.slug != this.current.slug) {
				useOrganizationsAuthStore().setCurrentAccount(account);
			}
		},
		connectAccount() {
			this.switchAccountModalOpened = false;
			this.connectAccountModalOpened = true;
		},
		disconnectAccount() {
			this.switchAccountModalOpened = false;
			this.disconnectAccountModalOpened = true;
		},
		login() {
			this.loading = true;
			organizationsFetch("/connect", {
				method: "POST",
				body: {
					email: this.manager.email,
					password: this.manager.password,
				},
			}).then((response) => {
				if (!response.authenticated) {
					this.toast.error("Vérifiez vos coordonnées", {
						timeout: 20000,
					});
				} else {
					if (response.exists) {
						this.toast.info(response.message);
					} else {
						persistedState.localStorage.setItem(
							"related",
							JSON.stringify(response.related)
						);
						persistedState.localStorage.setItem("token", response.token);

						this.toast.success("Connecté !");
						this.setCurrent();
					}
				}
				this.connectAccountModalOpened = false;
				this.switchAccountModalOpened = true;
				this.loading = false;
			});
		},
		logout() {
			this.loading = true;
			organizationsFetch("/disconnect", {
				method: "POST",
				body: {
					email: this.manager.email,
					password: this.manager.password,
				},
			}).then((response) => {
				if (!response.authenticated) {
					this.toast.error("Vérifiez vos coordonnées", {
						timeout: 20000,
					});
				} else {
					if (response.exists) {
						persistedState.localStorage.setItem(
							"related",
							JSON.stringify(response.related)
						);
						persistedState.localStorage.setItem("token", response.token);

						this.toast.success(response.message);
						this.setCurrent();
					} else {
						this.toast.info(response.message);
					}
				}
				this.disconnectAccountModalOpened = false;
				this.switchAccountModalOpened = true;
				this.loading = false;
			});
		},
	},
	components: {
		HalfCircleSpinner,
		Modal,
	},
};
</script>
<template>
	<div v-if="this.$device.isMobile">
		<div class="d-flex flex-column mobile-fixed-header">
			<div
				class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white position-relative">
				<div class="ml-2 h-75">
					<img src="/icon.png" class="h-100" />
				</div>
				<div class="navbar-brand mt-2 ml-2">
					<div>ALLODONS <sup>Beta</sup></div>
					<sup><b>{{ authStore.organization }}</b></sup>
				</div>
				<div class="navbar-nav align-items-center ms-auto text-black" @click="collapse">
					<div :class="{ 'menu-icon': true, active: menuCollapsed }">
						<span></span>
						<span id="middle"></span>
						<span></span>
					</div>
				</div>
			</div>
			<div class="card card-collapsable">
				<div class="collapse" :class="[{ show: menuCollapsed }]" id="menu" style="">
					<div class="card-body menu-container">
						<NuxtLink to="/organizations/home" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
								{
									'border-start-primary':
										currentRouteName == 'organizations-home',
								},
							]">
								<div class="card-header">
									<div :class="[
										{
											'text-primary':
												currentRouteName == 'organizations-home',
											'text-black-75':
												currentRouteName != 'organizations-home',
										},
									]">
										<i class="fa-solid fa-house mr-2"></i> Accueil
									</div>
								</div>
							</div>
						</NuxtLink>
						<div class="card card-collapsable mb-2">
							<a class="card-header" :class="[
								{
									'collapsed text-black': !pages_selected,
									'text-info': pages_selected,
								},
							]" href="#pagesCard" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="pagesCard">
								<i class="fas fa-table-list mr-2" style="position: relative; bottom: 2px"></i>
								<div>Mes pages</div>
								<div class="card-collapsable-arrow">
									<i class="fas fa-chevron-down"></i>
								</div>
							</a>
							<div class="collapse" :class="[{ show: pages_selected }]" id="pagesCard">
								<div class="card-body p-0">
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-pages-collection',
										},
									]" to="/organizations/pages/collection" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName ==
													'organizations-pages-collection',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName ==
															'organizations-pages-collection',
														'text-black-75':
															currentRouteName !=
															'organizations-pages-collection',
													},
												]">
													<i class="fa-solid fa-shop mr-2"></i> Page de collecte
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-pages-categories',
										},
									]" to="/organizations/pages/categories" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName ==
													'organizations-pages-categories',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName ==
															'organizations-pages-categories',
														'text-black-75':
															currentRouteName !=
															'organizations-pages-categories',
													},
												]">
													<i class="fa-solid fa-grip mr-2"></i> Catégories & Fêtes
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link ml-4" :class="[
										{
											active:
												currentRouteName == 'organizations-categories',
										},
									]" to="/organizations/subcategories" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName ==
													'organizations-subcategories',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName ==
															'organizations-subcategories',
														'text-black-75':
															currentRouteName !=
															'organizations-subcategories',
													},
												]">
													Sous-catégories
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-pages-campaigns',
										},
									]" to="/organizations/pages/campaigns" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-pages-campaigns',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName ==
															'organizations-pages-campaigns',
														'text-black-75':
															currentRouteName !=
															'organizations-pages-campaigns',
													},
												]">
													<i class="fa-solid fa-bullhorn mr-2"></i> Campagnes
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link ml-4" :class="[
										{
											active:
												currentRouteName == 'organizations-teams',
										},
									]" to="/organizations/teams" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName ==
													'organizations-teams',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName ==
															'organizations-teams',
														'text-black-75':
															currentRouteName !=
															'organizations-teams',
													},
												]">
													Équipes
												</div>
											</div>
										</div>
									</NuxtLink>
								</div>
							</div>
						</div>
						<div class="card card-collapsable mb-2">
							<a class="card-header" :class="[
								{
									'collapsed text-black': !data_selected,
									'text-info': data_selected,
								},
							]" href="#dataCard" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="dataCard">
								<i class="fas fa-table-list mr-2" style="position: relative; bottom: 2px"></i>
								<div>Mes données</div>
								<div class="card-collapsable-arrow">
									<i class="fas fa-chevron-down"></i>
								</div>
							</a>
							<div class="collapse" :class="[{ show: data_selected }]" id="dataCard">
								<div class="card-body p-0">
									<NuxtLink class="nav-link" to="/organizations/donors"
										@click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-donors',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-donors',
														'text-black-75':
															currentRouteName != 'organizations-donors',
													},
												]">
													<i class="fa-solid fa-users mr-2"></i> Donateurs
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" to="/organizations/donations"
										@click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-donations',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-donations',
														'text-black-75':
															currentRouteName != 'organizations-donations',
													},
												]">
													<i class="fa-solid fa-donate mr-2"></i> Dons
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" to="/organizations/subscriptions"
										@click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-subscriptions',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-subscriptions',
														'text-black-75':
															currentRouteName != 'organizations-subscriptions',
													},
												]">
													<i class="fa-solid fa-repeat mr-2"></i> Dons mensuels
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" to="/organizations/promises"
										@click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-promises',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-promises',
														'text-black-75':
															currentRouteName != 'organizations-promises',
													},
												]">
													<i class="fa-solid fa-clock mr-2"></i> Promesses
												</div>
											</div>
										</div>
									</NuxtLink>
								</div>
							</div>
						</div>
						<div class="card card-collapsable mb-2">
							<a class="card-header" :class="[
								{
									'collapsed text-black': !billing_selected,
									'text-info': billing_selected,
								},
							]" href="#billingCard" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="billingCard">
								<i class="fas fa-file-invoice mr-2"></i>
								<div>Facturation</div>
								<div class="card-collapsable-arrow">
									<i class="fas fa-chevron-down"></i>
								</div>
							</a>
							<div class="collapse" :class="[{ show: billing_selected }]" id="billingCard">
								<div class="card-body p-0">
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-billing-allodons',
										},
									]" to="/organizations/billing/allodons" @click="menuCollapsed = false">
										<div class="card border-start-lg" :class="[
											{
												'border-start-primary':
													currentRouteName ==
													'organizations-billing-allodons',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-tasks',
														'text-black-75':
															currentRouteName != 'organizations-tasks',
													},
												]">
													<i class="fa-solid fa-file-invoice mr-2"></i> Factures
													Allodons
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-billing-payouts',
										},
									]" to="/organizations/billing/payouts" @click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-billing-payouts',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary':
															currentRouteName == 'organizations-tasks',
														'text-black-75':
															currentRouteName != 'organizations-tasks',
													},
												]">
													<i class="fa-solid fa-money-bill-transfer mr-2"></i>
													Versements bancaires
												</div>
											</div>
										</div>
									</NuxtLink>
									<NuxtLink class="nav-link" to="/organizations/billing/tax"
										@click="menuCollapsed = false">
										<div class="card border-start-lg mb-2" :class="[
											{
												'border-start-primary':
													currentRouteName == 'organizations-billing-tax',
											},
										]">
											<div class="card-header">
												<div :class="[
													{
														'text-primary': currentRouteName == 'organizations-billing-tax',
														'text-black-75':
															currentRouteName != 'organizations-billing-tax',
													},
												]">
													<i class="fa-solid fa-print mr-2"></i> Déclaration fiscale
												</div>
											</div>
										</div>
									</NuxtLink>
								</div>
							</div>
						</div>
						<NuxtLink to="/organizations/profile" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
								{
									'border-start-primary':
										currentRouteName == 'organizations-profile',
								},
							]">
								<div class="card-header">
									<div :class="[
										{
											'text-primary':
												currentRouteName == 'organizations-profile',
											'text-black-75':
												currentRouteName != 'organizations-profile',
										},
									]">
										<i class="fa-solid fa-gear mr-2"></i> Paramètres association
									</div>
								</div>
							</div>
						</NuxtLink>
						<div class="card border-start-lg mb-2" @click="switchAccountModalOpened = true">
							<div class="card-header">
								<div class="text-pink">
									<i class="fa-solid fa-arrow-down-up-across-line mr-2"></i>
									Changer de compte
								</div>
							</div>
						</div>
						<div class="card border-start-lg mb-2" :class="[
							{
								'border-start-primary':
									currentRouteName == 'organizations-tasks',
							},
						]" @click="feedbackModalOpened = true">
							<div class="card-header">
								<div class="text-info">
									<i class="fa-solid fa-comments mr-2"></i> Partagez votre avis
								</div>
							</div>
						</div>
						<NuxtLink :to="config.public.API_HOST +
							'/dashboard/old?token=' +
							useOrganizationsAuthStore().currentToken()
							">
							<div class="card border-start-lg mb-10">
								<div class="card-header">
									<div class="text-primary">
										<i class="fa-solid fa-arrow-up-right-from-square mr-2"></i>
										Ancien espace association
									</div>
								</div>
							</div>
						</NuxtLink>
					</div>
				</div>
			</div>
		</div>
		<main class="mobile-body" v-show="!menuCollapsed">
			<div class="card">
				<slot />
				<div class="card-footer border-top-primary d-flex justify-content-between bg-white w-100"
					style="position: fixed; bottom: 0">
					<small class="font-weight-bold fullname">{{
						authStore.manager
					}}</small>
					<small class="font-weight-bold text-primary pointer" @click="authStore.logOut()">
						<i class="fa-solid fa-right-from-bracket"></i> Me déconnecter
					</small>
				</div>
			</div>
		</main>
	</div>
	<div v-else>
		<div
			class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white">
			<div class="order-1 order-lg-0 me-2 ms-lg-2 me-lg-0 h-75">
				<img src="/icon.png" class="h-100" />
			</div>
			<div class="navbar-brand pe-3 ps-4 ps-lg-2">
				ALLODONS <sub>Beta</sub>
				<!-- <NuxtLink
					:to="config.public.API_HOST + '/' + useOrganizationsAuthStore().slug"
					target="_blank"
				>
					<i
						class="fas fa-external-link-alt pointer ml-2"
						v-tooltip="'Ma page de collecte'"
					></i>
				</NuxtLink> -->
			</div>
			<div class="d-flex">
				<button class="btn text-primary mx-2" type="button" @click="aiToolModalOpened = true">
					Rédaction AI <i class="fa-solid fa-wand-magic-sparkles ml-2"></i>
				</button>
			</div>
			<div class="navbar-nav align-items-center ms-auto text-black">
				<button class="btn btn-sm mr-2" type="button" @click="qrCodeToolModalOpened = true"
					v-if="useOrganizationsAuthStore().currentQrCode() != null">
					<i class="fas fa-qrcode mr-2"></i>Mon QR Code
				</button>
				<NuxtLink :to="config.public.API_HOST +
					'/dashboard/old?token=' +
					useOrganizationsAuthStore().currentToken()
					">
					<button class="btn btn-sm mr-2" type="button">
						<i class="fa-solid fa-arrow-up-right-from-square mr-2"></i>
						Ancien espace association
					</button>
				</NuxtLink>
				<div class="font-weight-bold w-100 text-center text-primary"
					v-if="useOrganizationsAuthStore().related.split(',').length == 1">
					<i class="fa-solid fa-heart mr-2"></i>
					{{ authStore.organization }}
				</div>
				<button class="btn btn-sm mr-4 font-weight-bold d-flex align-items-center text-primary"
					@click="switchAccountModalOpened = true" v-tooltip="'Changer de compte'" v-else>
					<div class="avatar-circle mr-2">{{ managerInitials }}</div>
					<span>{{ authStore.organization }}</span>
					<i class="ml-1 fa-solid fa-arrow-down-up-across-line"></i>
				</button>

				<!-- <button
					class="btn btn-outline-primary btn-sm mr-4 font-weight-bold"
					@click="switchAccountModalOpened = true"
					v-tooltip="'Changer de compte'"
					v-else
				>
					<i class="fa-solid fa-arrow-down-up-across-line mr-2"></i>
					{{ authStore.organization }}
				</button> -->
			</div>
		</div>
		<div id="layoutSidenav">
			<div id="layoutSidenav_nav">
				<nav class="sidenav shadow-right sidenav-light">
					<div class="sidenav-menu">
						<div class="nav accordion" id="accordionSidenav">
							<NuxtLink class="nav-link" :class="[{ active: currentRouteName == 'organizations-home' }]"
								to="/organizations/home">
								<div class="nav-link-icon">
									<i class="fa-solid fa-house" style="position: relative; bottom: 2px"></i>
								</div>
								Tableau de bord
							</NuxtLink>
							<div class="sidenav-menu-heading">Mes pages</div>
							<NuxtLink class="nav-link" :class="[
								{
									active:
										currentRouteName == 'organizations-pages-collection',
								},
							]" to="/organizations/pages/collection">
								<div class="nav-link-icon">
									<i class="fa-solid fa-shop"></i>
								</div>
								Page de collecte
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[
								{
									active:
										currentRouteName == 'organizations-pages-categories'
								},
							]" to="/organizations/pages/categories" data-bs-target="#collapseCategories" aria-expanded="false"
								aria-controls="collapseCategories">
								<div class="nav-link-icon">
									<i class="fa-solid fa-grip"></i>
								</div>
								Catégories & Fêtes
							</NuxtLink>
							<div class="collapse" :class="[{ show: categories_selected }]" id="collapseCategories"
								data-bs-parent="#accordionSidenav">
								<nav class="sidenav-menu-nested nav accordion" id="accordionCategories">
									<NuxtLink class="nav-link" :class="[
										{
											active: [
												'organizations-subcategories'
											].includes(currentRouteName),
										},
									]" to="/organizations/subcategories">
										Sous-catégories
									</NuxtLink>
								</nav>
							</div>
							<NuxtLink class="nav-link" :class="[
								{
									active:
										currentRouteName == 'organizations-pages-campaigns',
								},
							]" to="/organizations/pages/campaigns" data-bs-target="#collapseTeams" aria-expanded="false"
								aria-controls="collapseTeams">
								<div class="nav-link-icon">
									<i class="fa-solid fa-bullhorn"></i>
								</div>
								Campagnes
							</NuxtLink>
							<div class="collapse" :class="[{ show: teams_selected }]" id="collapseTeams"
								data-bs-parent="#accordionSidenav">
								<nav class="sidenav-menu-nested nav accordion" id="accordionTeams">
									<NuxtLink class="nav-link" :class="[
										{
											active: [
												'organizations-teams'
											].includes(currentRouteName),
										},
									]" to="/organizations/teams">
										Équipes
									</NuxtLink>
								</nav>
							</div>
							<div class="sidenav-menu-heading">Mes données</div>
							<NuxtLink class="nav-link" :class="[
								{ active: donors_selected, collapsed: !donors_selected },
							]" to="/organizations/donors" data-bs-target="#collapseDonors" aria-expanded="false"
								aria-controls="collapseDonors">
								<div class="nav-link-icon">
									<i class="fa-solid fa-users"></i>
								</div>
								Donateurs
								<div class="sidenav-collapse-arrow">
									<i class="fas fa-angle-down"></i>
								</div>
							</NuxtLink>
							<div class="collapse" :class="[{ show: donors_selected }]" id="collapseDonors"
								data-bs-parent="#accordionSidenav">
								<nav class="sidenav-menu-nested nav accordion" id="accordionDonors">
									<NuxtLink class="nav-link" :class="[
										{
											active: [
												'organizations-donors',
												'organizations-donors-selected',
											].includes(currentRouteName),
										},
									]" to="/organizations/donors">
										<div class="nav-link-icon">
											<i class="fa-solid fa-list"></i>
										</div>
										Tous
									</NuxtLink>
									<NuxtLink to="/organizations/donors/map" class="nav-link text-blue" :class="[
										{
											active: currentRouteName == 'organizations-donors-map',
										},
									]">
										<div class="nav-link-icon text-blue">
											<i class="fa-solid fa-map-location-dot"></i>
										</div>
										Carte
									</NuxtLink>
									<NuxtLink to="/organizations/donors/duplicates" class="nav-link text-pink" :class="[
										{
											active:
												currentRouteName == 'organizations-donors-duplicates',
										},
									]">
										<div class="nav-link-icon text-pink">
											<i class="fa-solid fa-object-ungroup"></i>
										</div>
										Doublons
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-donors-lists',
										},
									]" to="/organizations/donors/lists">
										<div class="nav-link-icon">
											<i class="fa-solid fa-filter"></i>
										</div>
										Filtres & Groupes
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-donors-fields',
										},
									]" to="/organizations/donors/fields">
										<div class="nav-link-icon">
											<i class="fa-solid fa-border-top-left"></i>
										</div>
										Champs personnalisés
									</NuxtLink>
								</nav>
							</div>
							<NuxtLink class="nav-link" :class="[
								{
									active: donations_selected,
									collapsed: !donations_selected,
								},
							]" to="/organizations/donations" data-bs-target="#collapseDonations" aria-expanded="false"
								aria-controls="collapseDonations">
								<div class="nav-link-icon"><i class="fas fa-donate"></i></div>
								Dons
								<div class="sidenav-collapse-arrow">
									<i class="fas fa-angle-down"></i>
								</div>
							</NuxtLink>
							<div class="collapse" :class="[{ show: donations_selected }]" id="collapseDonations"
								data-bs-parent="#accordionSidenav">
								<nav class="sidenav-menu-nested nav accordion" id="accordionDonations">
									<NuxtLink class="nav-link" :class="[
										{
											active: [
												'organizations-donations',
												'organizations-donations-selected',
											].includes(currentRouteName),
										},
									]" to="/organizations/donations">
										<div class="nav-link-icon">
											<i class="fa-solid fa-list"></i>
										</div>
										Tous
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-donations-filters',
										},
									]" to="/organizations/donations/filters">
										<div class="nav-link-icon">
											<i class="fa-solid fa-filter"></i>
										</div>
										Filtres
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName == 'organizations-donations-fields',
										},
									]" to="/organizations/donations/fields">
										<div class="nav-link-icon">
											<i class="fa-solid fa-border-top-left"></i>
										</div>
										Champs personnalisés
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active: transactions_selected,
											collapsed: !transactions_selected,
										},
									]" to="/organizations/donations/disputes" data-bs-target="#collapseTransactions"
										aria-controls="collapseTransactions" aria-expanded="false">
										<div class="nav-link-icon">
											<i class="fa-solid fa-arrow-down-up-across-line"></i>
										</div>
										Transactions
										<div class="sidenav-collapse-arrow">
											<i class="fas fa-angle-down"></i>
										</div>
									</NuxtLink>
									<div class="collapse" :class="[{ show: transactions_selected }]"
										id="collapseTransactions" data-bs-parent="#accordionSidenavPagesMenu" style="">
										<nav class="sidenav-menu-nested nav">
											<NuxtLink class="nav-link" :class="[
												{
													active:
														currentRouteName ==
														'organizations-donations-disputes',
												},
											]" to="/organizations/donations/disputes">
												<div class="nav-link-icon">
													<i class="fa-solid fa-gavel"></i>
												</div>
												Contestes
											</NuxtLink>
											<NuxtLink class="nav-link" :class="[
												{
													active:
														currentRouteName ==
														'organizations-donations-refunds',
												},
											]" to="/organizations/donations/refunds">
												<div class="nav-link-icon">
													<i class="fa-solid fa-angles-left"></i>
												</div>
												Remboursements
											</NuxtLink>
										</nav>
									</div>
								</nav>
							</div>
							<NuxtLink class="nav-link" :class="[
								{
									active: subscriptions_selected,
									collapsed: !subscriptions_selected,
								},
							]" to="/organizations/subscriptions" data-bs-target="#collapseSubscriptions" aria-expanded="false"
								aria-controls="collapseSubscriptions">
								<div class="nav-link-icon"><i class="fas fa-repeat"></i></div>
								Dons mensuels
								<div class="sidenav-collapse-arrow">
									<i class="fas fa-angle-down"></i>
								</div>
							</NuxtLink>
							<div class="collapse" :class="[{ show: subscriptions_selected }]" id="collapseSubscriptions"
								data-bs-parent="#accordionSidenav">
								<nav class="sidenav-menu-nested nav accordion" id="accordionSubscriptions">
									<NuxtLink class="nav-link" :class="[
										{
											active: [
												'organizations-subscriptions',
												'organizations-subscriptions-selected',
											].includes(currentRouteName),
										},
									]" to="/organizations/subscriptions">
										<div class="nav-link-icon">
											<i class="fa-solid fa-list"></i>
										</div>
										Tous
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName ==
												'organizations-subscriptions-filters',
										},
									]" to="/organizations/subscriptions/filters">
										<div class="nav-link-icon">
											<i class="fa-solid fa-filter"></i>
										</div>
										Filtres
									</NuxtLink>
									<NuxtLink class="nav-link" :class="[
										{
											active:
												currentRouteName ==
												'organizations-subscriptions-fields',
										},
									]" to="/organizations/subscriptions/fields">
										<div class="nav-link-icon">
											<i class="fa-solid fa-border-top-left"></i>
										</div>
										Champs personnalisés
									</NuxtLink>
								</nav>
							</div>
							<NuxtLink class="nav-link" :class="[
								{
									active: [
										'organizations-promises',
										'organizations-promises-id',
									].includes(currentRouteName),
								},
							]" to="/organizations/promises">
								<div class="nav-link-icon">
									<i class="fa-solid fa-clock"></i>
								</div>
								Promesses
							</NuxtLink>
							<div class="sidenav-menu-heading">Facturation</div>
							<template v-if="false">
								<NuxtLink class="nav-link" :class="[
									{ active: currentRouteName == 'organizations-tags' },
								]" to="/organizations/tags">
									<div class="nav-link-icon">
										<i class="fa-solid fa-tag"></i>
									</div>
									Mes Étiquettes
								</NuxtLink>
								<div class="sidenav-menu-heading">_____</div>
							</template>
							<NuxtLink class="nav-link" :class="[
								{
									active:
										currentRouteName == 'organizations-billing-allodons',
								},
							]" to="/organizations/billing/allodons">
								<div class="nav-link-icon">
									<i class="fa-solid fa-file-invoice"></i>
								</div>
								Factures Allodons
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[
								{
									active:
										currentRouteName == 'organizations-billing-payouts',
								},
							]" to="/organizations/billing/payouts">
								<div class="nav-link-icon">
									<i class="fa-solid fa-money-bill-transfer"></i>
								</div>
								Versements bancaires
							</NuxtLink>
							<NuxtLink class="nav-link"
								:class="[{ active: currentRouteName == 'organizations-billing-tax' }]"
								to="/organizations/billing/tax">
								<div class="nav-link-icon">
									<i class="fa-solid fa-print"></i>
								</div>
								Déclaration fiscale
							</NuxtLink>
							<div class="sidenav-menu-heading">Paramètres</div>
							<NuxtLink class="nav-link" :class="[
								{ active: currentRouteName == 'organizations-profile' },
							]" to="/organizations/profile">
								<div class="nav-link-icon">
									<i class="fa-solid fa-gear" style="position: relative; bottom: 2px"></i>
								</div>
								Paramètres association
							</NuxtLink>
						</div>
					</div>
					<div class="sidenav-footer">
						<div class="sidenav-footer-content">
							<!-- <div class="sidenav-footer-title fullname">
								{{ authStore.manager }}
							</div>
							<div
								class="sidenav-footer-subtitle text-primary pointer"
								@click="authStore.logOut()"
							>
								<i class="fa-solid fa-right-from-bracket"></i> Me déconnecter
							</div> -->

							<button class="btn btn-sm mr-2" @click="feedbackModalOpened = true">
								<i class="fa-solid fa-comments mr-2"></i>Partagez votre avis
							</button>
						</div>
					</div>
				</nav>
			</div>
			<div id="layoutSidenav_content">
				<main>
					<slot />
				</main>
				<footer class="footer-admin mt-auto footer-light">
					<div class="container-xl px-4">
						<div class="row">
							<div class="col-md-6 small">Copyright &copy; ALLODONS SAS</div>
							<div class="col-md-6 text-md-end small">&middot;</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	</div>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="qrCodeToolModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header d-flex justify-content-between">
				<div>
					<i class="fa-solid fa-qrcode mr-2"></i>
					Voici le flashcode de votre page allodons. Très pratique pour vos
					affiches et publications !
				</div>
			</div>
			<div class="card-body">
				<img :src="useOrganizationsAuthStore().currentQrCode()" class="qr-code-src" />
			</div>
			<div class="card-footer text-right">
				<NuxtLink :to="useOrganizationsAuthStore().currentQrCode()" target="_blank">
					<button class="btn btn-sm btn-primary">
						<i class="fas fa-download"></i> Télécharger
					</button>
				</NuxtLink>
			</div>
		</div>
	</Modal>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="aiToolModalOpened"
		:width="this.$device.isMobile ? '90%' : '60%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'"
		:maskClosable="true">
		<div class="card">
			<div class="card-header d-flex justify-content-between">
				<div>
					<i class="fa-solid fa-wand-magic-sparkles mr-2"></i>
					Générateur de texte AI
				</div>
				<div>
					<i class="fa-solid fa-circle-xmark pointer" @click="aiToolModalOpened = false"></i>
				</div>
			</div>
			<div class="card-body">
				<OrganizationsAiTool />
			</div>
		</div>
	</Modal>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="feedbackModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header">
				<i class="fa-solid fa-comments mr-2"></i>
				Partager votre avis
			</div>
			<div class="card-body">
				<div class="d-flex justify-content-center" v-if="feedback_loading">
					<HalfCircleSpinner :animation-duration="1000" :size="30" color="#fa764b" />
				</div>
				<div v-else>
					Êtes-vous satisfait des services Allodons ?

					<div class="my-4 display-5 d-flex justify-content-between text-primary">
						<i :class="[choice == 1 ? 'fa-solid' : 'fa-regular']" class="fa-face-angry pointer"
							@click="choice = choice == 1 ? 0 : 1"></i>
						<i :class="[choice == 2 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-frown-open pointer" @click="choice = choice == 2 ? 0 : 2"></i>
						<i :class="[choice == 3 ? 'fa-solid' : 'fa-regular']" class="fa-regular fa-face-meh pointer"
							@click="choice = choice == 3 ? 0 : 3"></i>
						<i :class="[choice == 4 ? 'fa-solid' : 'fa-regular']" class="fa-regular fa-face-grin pointer"
							@click="choice = choice == 4 ? 0 : 4"></i>
						<i :class="[choice == 5 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-grin-beam pointer" @click="choice = choice == 5 ? 0 : 5"></i>
						<i :class="[choice == 6 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-grin-hearts pointer" @click="choice = choice == 6 ? 0 : 6"></i>
					</div>
					<div class="my-4 d-flex justify-content-between font-weight-bold">
						<sup class="text-danger">Peu satisfait</sup>
						<sup class="text-success">Extrêmement satisfait</sup>
					</div>
					<div class="mb-3">
						<label class="font-weight-bold small mb-1">Partager une opinion ou suggérer une idée</label>
						<textarea class="form-control" v-model="feedback"></textarea>
					</div>
					<div class="mt-4 text-right">
						<button class="btn btn-sm btn-success font-weight-bold" type="button" @click="sendFeedback">
							<i class="fa-solid fa-paper-plane"></i>
							Partager mon opinion
						</button>
					</div>
				</div>
			</div>
		</div>
	</Modal>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="switchAccountModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header">
				<div class="d-flex align-items-center justify-content-between">
					<div class="d-flex align-items-center">
						<div class="avatar-circle mr-2">{{ managerInitials }}</div>
						<span class="font-weight-bold fullname">{{
							authStore.manager
						}}</span>
					</div>
					<div class="text-primary pointer" @click="authStore.logOut()">
						<i class="fa-solid fa-right-from-bracket"></i> Me déconnecter
					</div>
				</div>
			</div>

			<div class="card-body">
				<b>Mes comptes associations :</b>
				<div class="d-flex justify-content-between" v-for="item in this.related">
					<button class="btn btn-sm my-2 w-100" :class="[
						current.slug == item.slug
							? 'btn-outline-primary'
							: 'btn-outline-primary',
					]" @click="switchAccount(item)">
						<i class="fa-solid fa-check-double" v-if="current.slug == item.slug"></i>
						{{ item.name }}
					</button>
				</div>
			</div>
			<div class="card-footer text-center w-100">
				<button class="btn btn-sm my-1 btn-primary w-100" @click="connectAccount()">
					<i class="fa-solid fa-plug"></i> Connecter un autre compte association
				</button>
				<button class="btn btn-sm my-2 btn-pink w-100" @click="disconnectAccount()">
					<i class="fa-solid fa-xmark"></i> Déconnecter un compte association
				</button>
			</div>
		</div>
	</Modal>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="connectAccountModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header">
				<i class="fa-solid fa-plug mr-2"></i>
				Connecter une association à votre compte actuel
			</div>
			<div class="card-body">
				<div v-if="!loading">
					<form>
						<div class="mb-3">
							<label class="small mb-1" for="inputEmailAddress">Email</label>
							<input class="form-control" id="inputEmailAddress" v-model="manager.email" name="email"
								type="email" autocomplete="email" />
						</div>
						<div class="mb-3">
							<label class="small mb-1" for="inputPassword">Mot de passe</label>
							<input class="form-control" id="inputPassword" v-model="manager.password" name="password"
								type="password" autocomplete="current-password" />
						</div>
					</form>
					<div class="d-flex justify-content-between mt-4">
						<button class="btn btn-sm btn-success w-100" @click.prevent="login">
							Connecter <i class="fa-solid fa-plug ml-2"></i>
						</button>
					</div>
				</div>
				<div class="d-flex justify-content-center" v-else>
					<HalfCircleSpinner :animation-duration="1000" :size="30" color="#fa764b" v-if="loading" />
				</div>
			</div>
		</div>
	</Modal>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="disconnectAccountModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header">
				<i class="fa-solid fa-lock mr-2"></i>
				Vous devez vous authentifier au compte à déconnecter
			</div>
			<div class="card-body">
				<div v-if="!loading">
					<form>
						<div class="mb-3">
							<label class="small mb-1" for="inputEmailAddress">Email</label>
							<input class="form-control" id="inputEmailAddress" v-model="manager.email" name="email"
								type="email" autocomplete="email" />
						</div>
						<div class="mb-3">
							<label class="small mb-1" for="inputPassword">Mot de passe</label>
							<input class="form-control" id="inputPassword" v-model="manager.password" name="password"
								type="password" autocomplete="current-password" />
						</div>
					</form>
					<div class="d-flex justify-content-between mt-4">
						<button class="btn btn-sm btn-pink w-100" @click.prevent="logout">
							<i class="fa-solid fa-xmark mr-2"></i>Déconnecter ce compte
						</button>
					</div>
				</div>
				<div class="d-flex justify-content-center" v-else>
					<HalfCircleSpinner :animation-duration="1000" :size="30" color="#fa764b" v-if="loading" />
				</div>
			</div>
		</div>
	</Modal>
</template>
<style scoped>
.qr-code-src {
	width: 100%;
}

.menu-icon {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 22px;
	height: 15px;
	cursor: pointer;
	transition: transform 0.3s ease;
}

.menu-icon span {
	display: block;
	height: 3px;
	background-color: black;
	border-radius: 2px;
	transition: all 0.3s ease;
}

/* Transition to cross when active */
.menu-icon.active span:nth-child(1) {
	transform: translateY(6px) rotate(45deg);
}

.menu-icon.active span:nth-child(2) {
	opacity: 0;
}

.menu-icon.active span:nth-child(3) {
	transform: translateY(-6px) rotate(-45deg);
}

.menu-icon #middle {
	width: 16px;
}

.mobile-fixed-header .card-collapsable #menu {
	background: black;
}

.avatar-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	background-color: #fa764b;
	/* Adjust color as needed */
	color: #fff;
	font-weight: bold;
	border-radius: 50%;
	font-size: 0.9rem;
	text-transform: uppercase;
}

.fullname {
	text-transform: capitalize;
}
</style>
